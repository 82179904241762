import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "~/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { Mail } from "lucide-react";
import { PatternFormat } from "react-number-format";
import { Link } from "@remix-run/react";

const emailSchema = z.object({
    email: z.string().email("Please enter a valid email address"),
});

const phoneSchema = z.object({
    phone: z.string().regex(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, "Please enter a valid phone number"),
});

export type EmailFormData = { email: string; type: 'email' };
export type PhoneFormData = { phone: string; type: 'phone' };
export type LoginFormData = EmailFormData | PhoneFormData;

interface LoginFormProps {
    onSubmit: (data: LoginFormData) => Promise<void>;
    isLoading?: boolean;
    error?: string;
}

export function LoginForm({ onSubmit, isLoading, error }: LoginFormProps) {
    const [useEmail, setUseEmail] = useState(true);

    const form = useForm<LoginFormData>({
        resolver: zodResolver(useEmail ? emailSchema : phoneSchema),
        defaultValues: useEmail ? { email: "" } : { phone: "" },
    });

    const handleSubmit = async (data: any) => {
        const submitData = useEmail
            ? { ...data, type: 'email' }
            : { ...data, type: 'phone' };
        await onSubmit(submitData);
    };

    return (
        <div className="flex flex-col space-y-3 w-full max-w-sm text-center">
            <div className="flex flex-col space-y-2 text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                    Welcome
                </h1>
                <div className="text-sm text-muted-foreground">
                    {useEmail ?
                        "Enter your email to sign in to your account" :
                        "Enter your phone number to sign in"
                    }
                </div>
            </div>

            <Form {...form} >
                <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-3">
                    <FormField
                        control={form.control}
                        name={useEmail ? "email" : "phone"}
                        render={({ field }) => (
                            <FormItem className="space-y-1.5">
                                <div className="flex justify-between items-center">
                                    <FormLabel className="text-sm font-medium">
                                        {useEmail ? "Email" : "Phone Number"}
                                    </FormLabel>
                                    <button
                                        onClick={() => setUseEmail(!useEmail)}
                                        className="text-sm text-blue-500 hover:text-blue-600 hover:underline"
                                        type="button"
                                    >
                                        Use {useEmail ? "Phone" : "Email"}
                                    </button>
                                </div>
                                <FormControl>
                                    {useEmail ? (
                                        <Input
                                            placeholder="name@example.com"
                                            type="email"
                                            className="text-base"
                                            style={{ fontSize: '16px' }}
                                            {...field}
                                        />
                                    ) : (
                                        <PatternFormat
                                            customInput={Input}
                                            format="+1 (###) ###-####"
                                            allowEmptyFormatting
                                            mask="_"
                                            className="text-base placeholder:text-muted-foreground"
                                            style={{ fontSize: '16px' }}
                                            onValueChange={(values) => {
                                                field.onChange(values.formattedValue);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                </FormControl>
                                <FormMessage className="text-xs" />
                            </FormItem>
                        )}
                    />

                    {error && (
                        <div className="text-sm font-medium text-destructive">
                            {error}
                        </div>
                    )}

                    {!useEmail && (
                        <p className="text-xs text-muted-foreground text-center">
                            We'll text you to confirm your number. Standard message and data rates apply.{' '}
                            <a href="/privacy" className="underline text-xs">
                                Privacy Policy
                            </a>
                        </p>
                    )}

                    <div className="flex justify-center space-x-2">
                        <Button
                            type="submit"
                            className="bg-[#0A0A0A] text-white hover:bg-[#2A2A2A]"
                            disabled={isLoading}
                        >
                            {isLoading ? "Signing in..." : "Continue"}
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
} 