import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useFetcher, useLoaderData, useNavigate, useLocation } from "@remix-run/react";
import { authenticator } from "~/.server/auth";
import { LoginForm } from "~/components/forms/auth/LoginForm";
import { getSession, commitSession } from "~/.server/session";
import type { LoginFormData } from "~/components/forms/auth/LoginForm";
import { IconFooter } from "~/components/layout/IconFooter";
import { generateMeta } from "~/utils/meta";

export const meta: MetaFunction = () => {
  return generateMeta({
    title: "Log In",
    index: false,
  });
};

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/account',
  })


  const session = await getSession(request.headers.get('Cookie'))
  session.unset('auth:email')
  session.unset('twilio:phone')
  const authError = session.get(authenticator.sessionErrorKey)
  const skipEmail = session.get('skipEmail')
  session.unset('skipEmail')
  // If they have a "public" search param, set it in the cookie
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const publicSearch = searchParams.get('public')
  if (publicSearch) {
    session.set('public', publicSearch)
  }

  // Commit session to clear any `flash` error message.
  return json(
    { authError, email: skipEmail },
    {
      headers: {
        'set-cookie': await commitSession(session),
      },
    },
  )
}

export async function action({ request }: ActionFunctionArgs) {
  const clonedRequest = request.clone();
  let formData;
  try {
    formData = await clonedRequest.formData();
  } catch (e) {
    return json({}, { status: 400 })
  }

  // Bot honeypot - this form should not be filled out by humans.
  if (formData.get('password') && formData.get('password') !== '') {
    console.log(`Bot detected, password field in login, email: ${formData.get('email')}`);
    return json({}, { status: 200 })
  }

  const action = formData.get('_action');

  // Handle resend code action
  if (action === 'resendCode') {
    const session = await getSession(request.headers.get('Cookie'));
    const email = session.get('auth:email');
    const phone = session.get('twilio:phone');

    if (email) {
      await authenticator.authenticate("totp-email", request, {
        successRedirect: "/verify",
        failureRedirect: "/login",
      });
    } else if (phone) {
      await authenticator.authenticate("sms", request, {
        successRedirect: "/verify",
        failureRedirect: "/login",
      });
    }

    return json({ error: "No email or phone found in session" }, { status: 400 });
  }

  const type = formData.get("type");

  if (type === "email") {
    const email = formData.get("email");
    if (!email || typeof email !== "string") {
      return json({ error: "Email is required" }, { status: 400 });
    }

    await authenticator.authenticate("totp-email", request, {
      successRedirect: "/verify",
      failureRedirect: "/login",
    });
  } else if (type === "phone") {
    const phone = formData.get("phone");
    if (!phone || typeof phone !== "string") {
      return json({ error: "Phone number is required" }, { status: 400 });
    }

    await authenticator.authenticate("sms", request, {
      successRedirect: "/verify",
      failureRedirect: "/login",
    });
  } else {
    return json({ error: "Invalid login type" }, { status: 400 });
  }
}

export default function Login() {
  const fetcher = useFetcher();
  const { error } = useLoaderData<typeof loader>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (data: LoginFormData) => {
    const formData = new FormData();
    formData.append("type", data.type);
    if (data.type === "email") {
      formData.append("email", data.email);
    } else {
      formData.append("phone", data.phone);
    }
    fetcher.submit(formData, { method: "POST" });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1 flex items-center justify-center p-4 animate-fade-in">
        <LoginForm
          onSubmit={handleSubmit}
          isLoading={fetcher.state !== "idle"}
          error={fetcher.data?.error || error}
        />
      </main>
      <IconFooter
        icons={[
          { id: 'explore', route: '/explore' },
          { id: 'about', route: '/about' },
          { id: 'support', route: '/support' },
          { id: 'login', route: '/login' }
        ]}
        selectedIcon="login"
        navigate={navigate}
        location={location}
      />
    </div>
  );
}
